import { css } from '@emotion/react';

// Biennale
import BiennaleRegular from '@/fonts/biennale-regular.otf';
import BiennaleMedium from '@/fonts/biennale-medium.otf';
import BiennaleSemiBold from '@/fonts/biennale-semi-bold.otf';
import BiennaleBold from '@/fonts/biennale-bold.otf';

const BiennaleFont = css`
  @font-face {
    font-family: Biennale;
    font-weight: 400;
    src: url(${BiennaleRegular});
  }
  @font-face {
    font-family: Biennale;
    font-weight: 500;
    src: url(${BiennaleMedium});
  }
  @font-face {
    font-family: Biennale;
    font-weight: 600;
    src: url(${BiennaleSemiBold});
  }
  @font-face {
    font-family: Biennale;
    font-weight: 700;
    src: url(${BiennaleBold});
  }
`;

export default BiennaleFont;
