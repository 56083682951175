import React from 'react';
import { Global, ThemeProvider } from '@emotion/react';

import reset from '@/styles/reset';
import theme from '@/styles/theme';

import NotifContextProvider from '@/contexts/notificationsContext';
import CompanyInfosProvider from '@/contexts/companyInfosContext';

import 'react-alice-carousel/lib/alice-carousel.css';

const Root = (element: HTMLElement): JSX.Element => {
  return (
    <>
      <Global styles={reset} />
      <ThemeProvider theme={theme}>
        <NotifContextProvider>
          <CompanyInfosProvider>{element.children}</CompanyInfosProvider>
        </NotifContextProvider>
      </ThemeProvider>
    </>
  );
};

export default Root;
